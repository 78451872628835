<template>
  <div id="app">
    <div id="menu" @click="toggleTextOverlay">
      <img src="./assets/menu-icon.png" alt="Menu" />
    </div>
    <div id="text-overlay" v-show="showTextOverlay">
      <div class="close-icon" @click="closeTextOverlay">
        <img src="./assets/close-icon.png" alt="Close" />
      </div>
      <div class="text-content">
        <p>Das Kompakthaus COMFORT 110, kaum durch den letzten Pinselstrich verziert, farblich abgestimmt, innen wie außen, kracht einfach zusammen, wie ein nachlässig zusammengezimmertes Baumhaus. Erst ein Knirschen und Knarzen, dann sackt der Keller nach unten weg, das Dach kommt runter und während er noch die Farbrolle in der Hand hält, hat das Traumhaus, der Traum vom Haus, seine denkbar kompakteste Form angenommen, als brusthoher Schutthaufen. </p>
      </div>
    </div>
    <div ref="canvasContainer">
      <canvas id="canvas"></canvas>
    </div>
  </div>
</template>

<script>

import * as THREE from 'three';

export default { 
  
setup() {    
    
  class Storey {
  
  constructor(SEGMENT_WIDTH, SEGMENT_HEIGHT, SEGMENT_THICKNESS, MATERIAL_CONCRETE) {

    this.SEGMENT_WIDTH = SEGMENT_WIDTH;
    this.SEGMENT_HEIGHT = SEGMENT_HEIGHT;
    this.SEGMENT_THICKNESS = SEGMENT_THICKNESS;
    this.MATERIAL_CONCRETE = MATERIAL_CONCRETE;
    this.group = new THREE.Group();  
      
  }

  createSegment(geometry, material, position, quaternion) {
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.copy(position);
    mesh.quaternion.copy(quaternion);

    return mesh;
  }

  createWindowSegment(posx, posz, rot) {
    const width = this.SEGMENT_WIDTH / 4 + this.SEGMENT_THICKNESS;
    const height = this.SEGMENT_HEIGHT;

    const left = this.createSegment(
      new THREE.BoxGeometry(width, height, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3(this.SEGMENT_WIDTH / 8, this.SEGMENT_HEIGHT / 2, 0),
      new THREE.Quaternion()
    );

    left.name = "windowLeftElement";

    const right = this.createSegment(
      new THREE.BoxGeometry(width, height, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3((this.SEGMENT_WIDTH / 8) * 7, this.SEGMENT_HEIGHT / 2, 0),
      new THREE.Quaternion()
    );

    right.name = "windowRightElement";

    const top = this.createSegment(
      new THREE.BoxGeometry(this.SEGMENT_WIDTH / 2, this.SEGMENT_HEIGHT / 4, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3(this.SEGMENT_WIDTH / 2, (this.SEGMENT_HEIGHT / 8) * 7, 0),
      new THREE.Quaternion()
    );

    top.name = "windowTopElement";

    const down = this.createSegment(
      new THREE.BoxGeometry(this.SEGMENT_WIDTH / 2, this.SEGMENT_HEIGHT / 4, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3(this.SEGMENT_WIDTH / 2, this.SEGMENT_HEIGHT / 8, 0),
      new THREE.Quaternion()
    );

    down.name = "windowDownElement";

    const windowSegment = new THREE.Group();
    windowSegment.add(left, right, top, down);

    windowSegment.position.set(posx, 0, posz);
    windowSegment.rotateY(rot);

    //windowSegment.name = "windowSegment";

    return windowSegment;
  }

  createDoorSegment(posx, posz, rot) {
    const width = this.SEGMENT_WIDTH / 4 + this.SEGMENT_THICKNESS;
    const height = this.SEGMENT_HEIGHT;

    const left = this.createSegment(
      new THREE.BoxGeometry(width, height, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3(this.SEGMENT_WIDTH / 8, this.SEGMENT_HEIGHT / 2, 0),
      new THREE.Quaternion()
    );  

    left.name = "doorLeftElement";

    const right = this.createSegment(
      new THREE.BoxGeometry(width, height, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3((this.SEGMENT_WIDTH / 8) * 7, this.SEGMENT_HEIGHT / 2, 0),
      new THREE.Quaternion()
    );

    right.name = "doorRightElement";

    const top = this.createSegment(
      new THREE.BoxGeometry(this.SEGMENT_WIDTH / 2, this.SEGMENT_HEIGHT / 4, this.SEGMENT_THICKNESS),
      this.MATERIAL_CONCRETE,
      new THREE.Vector3(this.SEGMENT_WIDTH / 2, (this.SEGMENT_HEIGHT / 8) * 7, 0),
      new THREE.Quaternion()
    );

    top.name = "doorTopElement";

    const doorSegment = new THREE.Group();    
    doorSegment.add(left, right, top);
    doorSegment.position.set(posx, 0, posz);
    doorSegment.rotateY(rot);
    //doorSegment.name = "doorSegment";
    
    return doorSegment;
  }

  createWallSegment(posx, posz, rot) {
    const wallgeometry = new THREE.BoxGeometry(this.SEGMENT_WIDTH + this.SEGMENT_THICKNESS, this.SEGMENT_HEIGHT, this.SEGMENT_THICKNESS);
    const wallmaterial = this.MATERIAL_CONCRETE;
    const wallelement = this.createSegment(wallgeometry, wallmaterial, new THREE.Vector3(this.SEGMENT_WIDTH / 2, this.SEGMENT_HEIGHT / 2, 0), new THREE.Quaternion());
    wallelement.name = "wallElement";

    const wallSegment = new THREE.Group();
    wallSegment.add(wallelement);
    wallSegment.position.set(posx, 0, posz);
    wallSegment.rotateY(rot);
    //wallSegment.name = "wallSegment";

    return wallSegment;
  }

  createNoWallSegment(posx, posz, rot) {
    const nowallgeometry = new THREE.PlaneGeometry(this.SEGMENT_WIDTH + this.SEGMENT_THICKNESS, this.SEGMENT_THICKNESS);
    const nowallmaterial = this.MATERIAL_CONCRETE;
    const nowallelement = this.createSegment(nowallgeometry, nowallmaterial, new THREE.Vector3(this.SEGMENT_WIDTH / 2, this.SEGMENT_HEIGHT, 0), new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, 0)));

    nowallelement.name = "noWallElement";

    const noWallSegment = new THREE.Group();
    noWallSegment.add(nowallelement);
    noWallSegment.position.set(posx, 0, posz);
    noWallSegment.rotateY(rot);
    //noWallSegment.name = "noWallSegment";

    return noWallSegment;
  }

  createOuterWall(length, posx, posz, rot) {
    const outerWall = new THREE.Group();

    for (let i = 0; i < length; i++) {
      let segment = this.createWindowSegment(0, this.SEGMENT_WIDTH * i, Math.PI / 2);
      outerWall.add(segment);
    }

    outerWall.position.set(posx, 0, posz);
    outerWall.rotation.y = rot;

    //outerWall.name = "outerWall";

    return outerWall;
  }

  createInnerWall(length, posx, posz, rot) {
    const innerWall = new THREE.Group();
    const types = ['noWall', 'wall', 'wall', 'door'];
    let segments = [];

    while (segments.length < length) {
      const randomType = types[Math.floor(Math.random() * types.length)];

      switch (randomType) {
        case 'door':
          segments.push(this.createDoorSegment(0, 0, 0));
          break;
        case 'noWall':
          segments.push(this.createNoWallSegment(0, 0, 0));
          break;
        case 'wall':
          segments.push(this.createWallSegment(0, 0, 0));
          break;
        default:
          console.error('Invalid segment type');
          break;
      }
    }

    let xPos = -(length * this.SEGMENT_WIDTH) / 2;

    segments.forEach((segment) => {
      segment.position.set(xPos, 0, 0);
      innerWall.add(segment);
      xPos += this.SEGMENT_WIDTH;
    });

    innerWall.position.set(posx, 0, posz);
    innerWall.rotation.y = rot;

    //innerWall.name = "innerWall";

    return innerWall;
  }

  createRoof() {
    const roofgeometry = new THREE.BoxGeometry(this.SEGMENT_WIDTH * 10, this.SEGMENT_THICKNESS, this.SEGMENT_WIDTH * 10);
    const roofmaterial = this.MATERIAL_CONCRETE;
    const roof = this.createSegment(roofgeometry, roofmaterial, new THREE.Vector3(0, this.SEGMENT_HEIGHT - this.SEGMENT_THICKNESS / 2, 0), new THREE.Quaternion());
    roof.name = "roofElement";

    return roof;
  }

  createFloor() {
    const floorgeometry = new THREE.BoxGeometry(this.SEGMENT_WIDTH * 10, this.SEGMENT_THICKNESS, this.SEGMENT_WIDTH * 10);
    const floormaterial = this.MATERIAL_CONCRETE;
    const floor = this.createSegment(floorgeometry, floormaterial, new THREE.Vector3(0, this.SEGMENT_THICKNESS / 2, 0), new THREE.Quaternion());
    floor.name = "floorElement";

    return floor;
  }

  createStorey() {
    const storey = new THREE.Group();
    const innerWallsHorizontal = [];
    const innerWallsVertical = [];

    for (let i = 0; i <= 8; i++) {
      innerWallsHorizontal.push(this.createInnerWall(10, 0, (-4 * this.SEGMENT_WIDTH) + this.SEGMENT_WIDTH * i, Math.PI));
      innerWallsVertical.push(this.createInnerWall(10, (-4 * this.SEGMENT_WIDTH) + this.SEGMENT_WIDTH * i, 0, Math.PI / 2));
    }

    const outerWallNorth = this.createOuterWall(10, (-4 * this.SEGMENT_WIDTH), (-5 * this.SEGMENT_WIDTH), Math.PI / 2);
    const outerWallSouth = this.createOuterWall(10, (-4 * this.SEGMENT_WIDTH), (5 * this.SEGMENT_WIDTH), Math.PI / 2);
    const outerWallWest = this.createOuterWall(10, (-5 * this.SEGMENT_WIDTH), (4 * this.SEGMENT_WIDTH), Math.PI);
    const outerWallEast = this.createOuterWall(10, (5 * this.SEGMENT_WIDTH), (4 * this.SEGMENT_WIDTH), Math.PI);

    const roof = this.createRoof();
    const floor = this.createFloor();

    storey.add(
      outerWallNorth, outerWallSouth, outerWallEast, outerWallWest,
      ...innerWallsHorizontal, ...innerWallsVertical, roof, floor
    );  

    storey.name = "storey";

      return storey;
    }
  }  
  return { Storey};  
  },

  name: 'App',
  data() {
    return {
      showTextOverlay: false, 
      MATERIAL_CONCRETE: new THREE.MeshLambertMaterial({ color: 0x808080 }),        
      AXES_HELPER: new THREE.AxesHelper(5), 
      controls: {},
      INNERWALL_LENGTH: 80,
      SEGMENT_HEIGHT: 2.7,
      SEGMENT_WIDTH: 2,
      SEGMENT_THICKNESS: 0.1, 
      isCollision: false,
      recoveryPosition: new THREE.Vector3(),
      player: {
        height: 1,
        turnSpeed: .09,
        speed: .1,
        jumpHeight: .2,       
        playerJumps: false
      },      
    }
  },
  
  mounted() {  

  this.scene = new THREE.Scene();
  this.initThreeJS();
  
  window.addEventListener('resize', this.windowResize);
  document.addEventListener('keydown', ({ key }) => { this.controls[key] = true });
  document.addEventListener('keyup', ({ key }) => { this.controls[key] = false });
  },

  beforeUnmount() {
  window.removeEventListener('resize', this.windowResize);
  document.removeEventListener('keydown', ({ key }) => { this.controls[key] = true });
  document.removeEventListener('keyup', ({ key }) => { this.controls[key] = false });
  },
    
  methods: {    

  createCollisionBodies(parentMesh) {
  const collisionBodies = [];

  // Traverse through the children of the parent mesh
  parentMesh.traverse((object) => {
      if (object.isMesh && object.name !== "noWallElement" && object.name !== "") {
          // Get world coordinates, dimensions, and quaternion
          const worldPosition = new THREE.Vector3();
          const worldQuaternion = new THREE.Quaternion();
          const worldScale = new THREE.Vector3();

          object.getWorldPosition(worldPosition);
          object.getWorldQuaternion(worldQuaternion);
          object.getWorldScale(worldScale);          

          // Create a Box3 bounding box and apply position, rotation, and scale
          const boundingBox = new THREE.Box3().setFromObject(object);
          boundingBox.name = object.name;
          collisionBodies.push(boundingBox);
          //console.log(collisionBodies);
      }
  });

  return collisionBodies;
  },
     
  toggleTextOverlay() {
    this.showTextOverlay = !this.showTextOverlay;
  },  

  closeTextOverlay() {
    this.showTextOverlay = false;
  },
  
  windowResize() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    }, 50); // Adjust the debounce time (in milliseconds) to your needs
  },

  control() {
    const angle = this.camera.rotation.y;

      if (this.controls['ArrowDown']) {
          this.camera.position.x += Math.sin(angle) * this.player.speed;
          this.camera.position.z += Math.cos(angle) * this.player.speed;
      }
      
      if (this.controls['ArrowUp']) {
          this.camera.position.x -= Math.sin(angle) * this.player.speed;
          this.camera.position.z -= Math.cos(angle) * this.player.speed;
      }    
      
      if (this.controls['ArrowRight']) {
          this.camera.rotation.y -= this.player.turnSpeed;
      }
      
      if (this.controls['ArrowLeft']) {
          this.camera.rotation.y += this.player.turnSpeed;
      }
  },    

  initThreeJS() {
    const scene = new THREE.Scene();  
    
    //create storey
    const planStorey = new this.Storey(this.SEGMENT_WIDTH, this.SEGMENT_HEIGHT, this.SEGMENT_THICKNESS, this.MATERIAL_CONCRETE);   
    const storey = planStorey.createStorey();
    storey.name = "storey";
    scene.add(storey);
           
    //Camera
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.camera.position.set(0, this.player.height , 0);     
         
   // ego sphere
    const egoGeometry = new THREE.BoxGeometry (0.05,0.05,0.05);
    const egoMaterial = new THREE.MeshLambertMaterial({ color: 0xff0000 });
    const ego = new THREE.Mesh(egoGeometry, egoMaterial);
    ego.visible = false;
    scene.add(ego);
        
    //Renderer
    this.renderer = new THREE.WebGLRenderer({ canvas: document.getElementById('canvas'), antialias: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    scene.background = new THREE.Color(0x000a4a);
          
    //Light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 3, 5);
    scene.add(directionalLight);

    const spotLight = new THREE.SpotLight(0xffeeee);
    spotLight.position.set(2, 2, 3);
    spotLight.castShadow = true;
    scene.add(spotLight);
          
    this.createCollisionBodies(storey, scene);   
                         
    let animate = () => {
    this.control();

    if (!this.isCollision) {
        // Update ego position and orientation based on camera
        const forward = new THREE.Vector3(0, 0, 0.5);
        forward.applyQuaternion(this.camera.quaternion);

        ego.position.copy(this.camera.position); 
        //we are just under the window opening
        ego.position.y = 0.65;       
        ego.position.add(forward.clone().multiplyScalar(-1)); // Adjust the distance behind the camera
        ego.quaternion.copy(this.camera.quaternion);

        // Get the bounding box of the ego object
        const egoBB = new THREE.Box3().setFromObject(ego);

        const collisionBodies = this.createCollisionBodies(scene);

        // Check for collisions with bounding boxes from createCollisionBodies()
        collisionBodies.forEach((boundingBox) => {
          if (egoBB.intersectsBox(boundingBox)) {
                // Collision detected between ego and the current bounding box
                console.log("Collision with", boundingBox.name);

                // Set collision state to true and store the recovery position
                this.isCollision = true;
                this.recoveryPosition.copy(this.camera.position);
                                
                // Stop ego and camera immediately               
                this.camera.position.copy(this.recoveryPosition);
              }
            });
          } else {
        // If collision has occurred, smoothly move the camera and ego to the recovery position with lerp
        this.camera.position.lerp(this.recoveryPosition, 0.4);       

        // Optionally, you can check how close the camera is to the recovery position
        // and reset the collision state when it's close enough to avoid unnecessary lerping.
        const distance = this.camera.position.distanceTo(this.recoveryPosition);
        if (distance < 0.001) {
            this.isCollision = false;
        }
      }
        
      this.renderer.render(scene, this.camera);
      requestAnimationFrame(animate);
   
      };

    animate();

    },
  },
};

</script>

<style>
#app {
position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#menu {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

#text-overlay {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  border: 2px solid rgb(48, 48, 48);
  z-index: 1;
}

.close-icon {
  cursor: pointer;
}

.text-content {
  display: flex;
  flex-wrap: wrap;
  font-size: large;
  font-family: 'Courier New', Courier, monospace;
  align-items: flex-start;
  gap: 10px;
  line-height: 1.5;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}
</style>